// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sticky_Jh8kG{bottom:1rem;display:flex;flex-direction:row;margin:1rem auto 0;position:sticky;width:-moz-fit-content;width:fit-content;z-index:999999999}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sticky": "sticky_Jh8kG"
};
module.exports = ___CSS_LOADER_EXPORT___;
