
import { getPropertyDocumentsQuery, getPropertyCondosByBfeNumberQuery, getPropertyAreaQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";

import {
  createAreasDownload,
  createBBRSheetDownload,
  createOwnersOverviewDownload,
  createMortgagesDownload,
  createEnergyLabelDownload,
  createAnnualReportDownload,
  createSoilConDownload,
  createLocalPlanDownload,
  createMunicipalityPlanDownload,
  createCorporateDiagramDownload,
} from "~/helpers/download-helpers.js";

import ListItem from "../ListItem.vue";
import DownloadPopup from "../DownloadPopup.vue";
import Me from "~/graphql/Authentication/Me.gql";
import Keys from "~/graphql/Download/Keys.gql";
import DownloadQuery from "~/graphql/Download/Download.gql";
import Corporate from "~/components/Corporate.vue";

export default {
  name: "Documents",
  components: {
    ListItem,
    DownloadPopup,
    Corporate,
  },
  props: {
    documents: {
      type: Object,
      required: true,
    },
    condos: {
      type: Array,
      required: true,
    },
    areas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: {
        areaTable: {
          value: false,
          handler: () => createAreasDownload(this.areas, this.condos, this.language),
        },

        ownersOverview: {
          value: false,
          handler: () => createOwnersOverviewDownload(this.documents, this.condos, this.language),
        },

        bbrSheet: {
          value: false,
          handler: () => createBBRSheetDownload(this.documents.bfeNumber, "BBR_SHEET"),
        },

        bbrSheetCondo: {
          value: false,
          handler: () => this.condos.map((c) => createBBRSheetDownload(c.bfeNumber, "BBR_SHEET_CONDO_IN_MAIN_PROPERTY")),
        },
      },

      tinglysning: {
        mortgages: {
          value: false,
          handler: () => createMortgagesDownload(this.documents, this.condos, this.language),
        },

        acts: { value: false, serverside: "ACT" },
        liabilities: { value: false, serverside: "LIABILITIES" },
        endorsementLiabilities: { value: false, serverside: "ENDORSEMENT_LIABILITIES" },
        easements: { value: false, serverside: "EASEMENTS" },
        endorsementEasements: { value: false, serverside: "ENDORSEMENT_EASEMENTS" },
        deeds: { value: false, serverside: "DEED" },
        endorsementDeeds: { value: false, serverside: "ENDORSEMENT_DEED" },
        entitlements: { value: false, serverside: "ENTITLEMENT" },
        attests: { value: false, serverside: "ATTEST" },
      },

      energyLabels: {},
      corporateDiagrams: {},
      annualReports: {},
      localPlans: {},
      municipalityPlans: {},
      soilContaminations: {},
      modalOpen: false,
      documentsToDownload: [],
      language: "",
      showError: false,
    };
  },
  apollo: {
    documents: getPropertyDocumentsQuery,
    condos: getPropertyCondosByBfeNumberQuery,
    areas: getPropertyAreaQuery,

    me: {
      query: Me,
    },
  },

  computed: {
    selected() {
      return this.allItems.filter((item) => item && item.value === true);
    },

    allItems() {
      return [
        ...Object.values(this.items),
        ...Object.values(this.tinglysning),
        ...Object.values(this.energyLabels ?? {}),
        ...Object.values(this.annualReports ?? {}),
        ...Object.values(this.corporateDiagrams ?? {}),
        ...Object.values(this.localPlans ?? {}),
        ...Object.values(this.municipalityPlans ?? {}),
        ...Object.values(this.soilContaminations ?? {}),
      ];
    },

    dataIsLoading() {
      if (!this.$apolloData) {
        return true;
      }

      const value = this.$apolloData.queries.documents?.loading || this.$apolloData.queries.condos?.loading || this.$apolloData.queries.areas?.loading;

      // watching this computed property messed up everything. Temporary solution
      if (!value) {
        this.$nextTick(() => {
          this.populateEnergyLabels();
          this.populateAnnualReports();
          this.populateCorporateDiagrams();
          this.populateLocalPlans();
          this.populateMunicipalityPlans();
          this.populateSoilContaminations();
        });
      }
      return value;
    },

    buildingDocuments() {
      return [this.items.areaTable, this.items.bbrSheet, this.items.bbrSheetCondo, ...Object.values(this.energyLabels ?? {})];
    },

    buildingsMarkAll: {
      get() {
        return this.buildingDocuments.every((x) => x.value === true);
      },

      set(val) {
        this.buildingDocuments.forEach((x) => (x.value = val));
      },
    },

    buildingsMarkIndeterminate() {
      return !this.buildingDocuments.every((x, i, a) => x && x.value === a[0].value);
    },

    ownerDocuments() {
      return [this.items.ownersOverview, ...Object.values(this.corporateDiagrams), ...Object.values(this.annualReports)];
    },

    ownersMarkAll: {
      get() {
        return this.ownerDocuments.every((x) => x.value === true);
      },

      set(val) {
        this.ownerDocuments.forEach((x) => (x.value = val));
      },
    },

    ownersMarkIndeterminate() {
      return !this.ownerDocuments.every((x, i, a) => x.value === a[0].value);
    },

    areaDocuments() {
      return [...Object.values(this.municipalityPlans), ...Object.values(this.localPlans), ...Object.values(this.soilContaminations)];
    },

    areasMarkAll: {
      get() {
        return this.areaDocuments.every((x) => x.value === true);
      },

      set(val) {
        this.areaDocuments.forEach((x) => (x.value = val));
      },
    },

    areasMarkIndeterminate() {
      return !this.areaDocuments.every((x, i, a) => x.value === a[0].value);
    },

    tinglysningMarkAll: {
      get() {
        return Object.values(this.tinglysning).every((x) => x.value === true);
      },

      set(val) {
        const items = Object.values(this.tinglysning);
        items.forEach((x) => (x.value = val));
      },
    },

    tinglysningMarkIndeterminate() {
      return !Object.values(this.tinglysning).every((x, i, a) => x.value === a[0].value);
    },

    address: function () {
      let address = this.documents.plots?.find((plot) => plot != null && plot.address != null)?.address;
      if (address != null) {
        return `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.city}`;
      } else {
        return this.$route.params.id.toString();
      }
    },

    propertyType() {
      return this.isDividedInCondos ? "MAIN_PROPERTY_DIVIDED_IN_CONDOS" : "MAIN_PROPERTY";
    },

    isDividedInCondos() {
      return this.documents.isDividedInCondos || this.condos?.length > 0;
    },
  },

  methods: {
    initiateDownload(selection) {
      this.modalOpen = true;
      this.documentsToDownload = selection;
    },

    initiateDownloadAll(selection) {
      this.setAll(true);
      this.initiateDownload(selection);
    },

    async download(selection, options) {
      this.language = options.selectedLocaleContentLanguage;

      let folderTemplateId = null;
      let namingTemplateId = null;

      folderTemplateId = options.selectedTemplate.id;

      const frontEndLinks = selection.filter((x) => !x.serverside).flatMap((x) => x.handler());
      const backEndLinks = selection.filter((x) => x.serverside).map((x) => x.serverside);

      const resp = await this.$apollo.query({
        query: Keys,
        variables: {
          type: backEndLinks,
        },
      });

      //TODO: Jeppe - When we want to do templates in the frontend, use this
      //const mappedKeys = getMappedKeys(resp.data.keys, options.selectedLocale);

      const bfeNumber = this.$route.params.id;

      const plot = this.documents.plots?.find((plot) => plot != null);

      let download = {
        recipients: [],
        message: null,
        username: this.me.name,
        filename: "Estaid Download - " + bfeNumber.toString(),
        properties: [
          {
            address: this.address,
            bfeNumber: parseInt(bfeNumber, 10),
            downloadCondos: this.isDividedInCondos,
            selection: {
              includes: resp.data.keys.map((x) => x.type),
              links: await Promise.all(frontEndLinks),
            },
          },
        ],
      };

      if (folderTemplateId != null) {
        download.fileDownloadTemplateId = folderTemplateId;
      } else if (namingTemplateId != null) {
        download.templateId = namingTemplateId;
      }

      if (this.isDividedInCondos) {
        download.properties[0].matrikelNumber = plot?.matrikelNumber;
        download.properties[0].cadastralDistCode = parseInt(plot?.ownersGuildCode, 10);
      } else {
        download.properties[0].bfeNumber = parseInt(bfeNumber, 10);
      }

      if (options.reciever == "me" || options.getCopy) {
        download.recipients.push(this.me.email);
      }

      if (options.reciever == "other") {
        download.message = options.message;
        download.recipients.push(options.email);
      }

      this.$apollo
        .query({
          query: DownloadQuery,
          variables: { download: download },
        })
        .then(() => {
          this.modalOpen = false;
        })
        .catch(() => {
          this.showError = true;
        });
    },

    setAll(val) {
      this.allItems.forEach((x) => {
        x.value = val;
      });
    },

    populateEnergyLabels() {
      this.energyLabels = this.documents?.plots
        ?.flatMap((p) => p.buildings?.flatMap((b) => b.energyLabel))
        ?.filter((l) => l != null)
        ?.filter((label, pos, arr) => {
          return label.link != null && arr.map((mapObj) => mapObj[label.link]).indexOf(label[label.link]) === pos;
        })
        ?.sort((a, b) => a.buildingNumber - b.buildingNumber)
        .reduce((obj, label, i) => {
          obj[i] = {
            id: label.id,
            value: false,
            handler: () => createEnergyLabelDownload(label, this.language),
            data: label,
          };

          return obj;
        }, {});
    },

    populateAnnualReports() {
      const motherowners = this.documents.owners?.filter((x) => x && x.company);
      const condoowners = this.condos?.flatMap((x) => x.owners).filter((x) => x && x.company);

      const companies = [...(motherowners ?? []), ...(condoowners ?? [])]
        //remove duplicates
        .filter((x, i, s) => s.findIndex((y) => y.cvrNumber === x.cvrNumber) == i)
        .filter((x) => x.company.keyFigures?.length > 0);

      if (companies == null || companies.length == 0) {
        return;
      }

      this.annualReports = companies.reduce((obj, owner) => {
        obj[owner.cvrNumber] = {
          id: `annualreport_${owner.cvrNumber}`,
          value: false,
          handler: () => createAnnualReportDownload(owner, this.language),
          data: owner,
        };

        return obj;
      }, {});
    },

    populateCorporateDiagrams() {
      const motherowners = this.documents.owners?.filter((x) => x && x.company);
      const condoowners = this.condos.flatMap((x) => x.owners).filter((x) => x && x.company);

      const companies = [...(motherowners ?? []), ...(condoowners ?? [])]
        //remove duplicates
        .filter((x, i, s) => s.findIndex((y) => y.cvrNumber === x.cvrNumber) == i);

      if (companies == null || companies.length == 0) {
        return;
      }

      this.corporateDiagrams = companies.reduce((obj, owner) => {
        obj[owner.cvrNumber] = {
          id: `corporate_${owner.cvrNumber}`,
          value: false,
          handler: () => this.createCorporateDiagramDownload(owner, this.language),
          data: owner,
        };

        return obj;
      }, {});
    },

    populateLocalPlans() {
      this.localPlans = this.documents?.plots
        .filter((x) => x && x.geometry)
        .flatMap((x) => x.geometry.plans)
        .filter((x) => x && x.type === "local")
        ?.reduce((obj, plan) => {
          obj[plan.id] = {
            id: plan.id,
            value: false,
            handler: () => createLocalPlanDownload(plan, this.language),
            data: plan,
          };

          return obj;
        }, {});
    },

    populateMunicipalityPlans() {
      this.municipalityPlans = this.documents?.plots
        .filter((x) => x && x.geometry)
        .flatMap((x) => x.geometry.plans)
        .filter((x) => x && x.type === "municipality")
        ?.reduce((obj, plan) => {
          obj[plan.id] = {
            id: plan.id,
            value: false,
            handler: () => createMunicipalityPlanDownload(plan, this.language),
            data: plan,
          };

          return obj;
        }, {});
    },

    populateSoilContaminations() {
      this.soilContaminations = this.documents?.plots?.reduce((obj, plot) => {
        obj[`${plot.ownersGuildCode}-${plot.matrikelNumber}`] = {
          id: `${plot.ownersGuildCode}-${plot.matrikelNumber}`,
          value: false,
          handler: () => createSoilConDownload(plot, this.language),
          data: plot,
        };

        return obj;
      }, {});
    },

    createCorporateDiagramDownload(owner, language) {
      const canvas = document.querySelectorAll(`[id^='${owner.cvrNumber}']`)[0].firstElementChild.firstElementChild;
      const link = canvas.toDataURL().split(",")[1];

      return createCorporateDiagramDownload(link, owner, language);
    },

    closeModal() {
      this.modalOpen = false;
      this.showError = false;
    },
  },
};
