
export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    status: {
      type: String,
      default: " – ",
    },

    format: {
      type: String,
      default: " – ",
    },

    source: {
      type: String,
      default: " – ",
    },

    link: {
      type: String,
      default: "",
    },

    value: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
