
import { getPropertyDocumentsQuery, getPropertyCondosByBfeNumberQuery, getPropertyAreaQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import PropertyDocuments from "../components/mainproperty/PropertyDocuments.vue";
import CondoDocuments from "../components/condo/CondoDocuments.vue";
import BuildingOnForeignPlotDocuments from "../components/buildingonforeignplot/BuildingOnForeignPlotDocuments.vue";

export default {
  title() {
    return `${this.$t("MENU_DOCUMENTS")}`;
  },
  apollo: {
    documents: getPropertyDocumentsQuery,
    condos: getPropertyCondosByBfeNumberQuery,
    areas: getPropertyAreaQuery,
  },
  components: {
    PropertyDocuments,
    CondoDocuments,
    BuildingOnForeignPlotDocuments,
  },
  computed: {
    loading: function () {
      return this.$apollo.queries.documents.loading || this.$apollo.queries.condos.loading || this.$apollo.queries.areas.loading;
    },
    isProperty: function () {
      return this.documents.plots != null;
    },
    isCondo: function () {
      return this.documents.condo != null;
    },
    isBuildingOnForeignPlot: function () {
      return this.documents.buildingOnForeignPlot != null;
    },
  },
};
